import React, { useState } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import useGetWords from '../hooks/useGetWords'
import useRandom from '../hooks/useRandom'
import Button from '@material-ui/core/Button'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'

export default () => {
  const [random, setRandom] = useState(),
    words = useGetWords(random),
    randomInt = useRandom(),
    generate = () => {
      setRandom(randomInt)
    },
    wordOutput = words.length === 0 ? '...' : words[0].word

  return (
    <Layout>
      <SEO title="&#9786;" />
      <h1>Zufallstreffer</h1>
      <Card>{wordOutput}</Card>
      <ButtonWrapper>
        <Button
          variant="contained"
          color="primary"
          className="btn"
          endIcon={<LibraryBooksIcon />}
          onClick={generate}
        >
          Los
        </Button>
      </ButtonWrapper>
    </Layout>
  )
}

const Card = styled.div`
  border: 1px solid #ccc;
  background-color: #f6f4fa;
  border-radius: 4px;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  padding: 1rem 1.1rem;
  font-size: 2.5rem;
  text-align: center;
`

const ButtonWrapper = styled.div`
  .btn {
    width: 100%;
    font-size: 1.5rem;
  }
`
